import { template as template_bd3636174cf1410d82f5389c65218efe } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_bd3636174cf1410d82f5389c65218efe(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
