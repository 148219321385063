import { template as template_585d5f057abc4fd39806ff1eac347bd3 } from "@ember/template-compiler";
const FKLabel = template_585d5f057abc4fd39806ff1eac347bd3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
